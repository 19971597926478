import { useStaticQuery, graphql } from 'gatsby'

export const useFragranceMetadata = () => {
    const { allFragrancesCsv } = useStaticQuery(
      graphql`
        query fragranceMetadata {
          allFragrancesCsv {
            edges {
              node {
                slug
                name
                shortDescription
              }
            }
          }
        }
    `)
    return allFragrancesCsv
}