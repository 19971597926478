import { useFragranceMetadata } from "hooks/useFragranceMetadata.js"

export default function GetFragranceDescription(fragranceSlug) {
  const { edges } = useFragranceMetadata();
  const fragrance = edges.find(
    edge => edge.node.slug === fragranceSlug
  )
  if (!fragrance) {
    return null
  }
  return fragrance.node.shortDescription
}


