import React from "react";
import { Link, graphql } from "gatsby"
import { GatsbySeo, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { renderRichText } from "gatsby-source-contentful/rich-text"

import GetFragranceDescription from 'utils/GetFragranceDescription.js';

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Image from 'components/Image/Image';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";

import { lighten, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Hidden from "@material-ui/core/Hidden";

import useIsClient from 'hooks/useIsClient';

import styles from "assets/jss/material-kit-pro-react/views/productFamilyFragranceTemplate.js";

export const query = graphql`
  query ($categoryBannerImageName: String!, $slug: String!) {
    allProductsCsv(filter: {productTypeSlug: {eq: $slug}, isDiscontinued: {eq: "false"}, isHidden: {eq: "false"}}) {
      edges {
        node {
          name
          slug
          shortDescription
          image
          fragranceSlug
          price
          price_usd
        }
      }
    }
    allFragrancesCsv {
      edges {
        node {
          slug
          name
          shortDescription
        }
      }
    }
    contentfulProductFamily(slug: {eq: $slug}) {
      name
      shortDescription
      richDescription {
        raw
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
        file {
          fileName
        }
      }
      slug
      category {
        slug
        name
      }
    }
    bannerImage: imageSharp(resize: {originalName: {eq: $categoryBannerImageName}}) {
      gatsbyImageData(quality: 50)
    }
    bannerImageMobile: imageSharp(resize: {originalName: {eq: $categoryBannerImageName}}) {
      gatsbyImageData(width: 1200)
    }
  }
`
const useStyles = makeStyles(styles);

// define options for documentToReactComponents (json > richText)
const options = {
  renderNode: {
    "embedded-asset-block": (node) => {
      const alt = node.data.target.fields.title['en-CA']
      const url = node.data.target.fields.file['en-CA'].url

      return <img alt={alt} src={url} />
    }
  }
}

///// FOR PRODUCT TABLE

// define data model
function createData(name, url, family, description, price, img, fragranceSlug ) {
  return { name, url, family, description, price, img, fragranceSlug};
}

// create array to store fragrance data
const rows = [];
var headCells;

// load data into array
function loadTableData(props) {
  rows.length = 0;
  if (props.pageContext.locale.oglanguageCode === "en_US") {
    props.data.allProductsCsv.edges.map((edge) => (
        rows.push(
            createData(
                edge.node.name,
                props.pageContext.locale.path + '/shop/' + edge.node.slug,
                '',
                edge.node.shortDescription,
                edge.node.price_usd,
                getImage(edge.node.image, edge.node.name),
                edge.node.fragranceSlug
            )
        )
    ))
  } else {
    props.data.allProductsCsv.edges.map((edge) => (
        rows.push(
            createData(
                edge.node.name,
                props.pageContext.locale.path + '/shop/' + edge.node.slug,
                '',
                edge.node.shortDescription,
                edge.node.price,
                getImage(edge.node.image, edge.node.name),
                edge.node.fragranceSlug
            )
        )
    ))
}

headCells = [
  { id: 'img', numeric: false, disablePadding: true, disableSort: true, label: '' },
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'description', numeric: false, disablePadding: true, disableSort: true, label: 'Description' },
  { id: 'price', numeric: false, disablePadding: true, label: 'Price' }
];

}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

if (typeof window !== `undefined`) {

  if (window.screen.width <= 350) {

    headCells = [
      { id: 'img', numeric: false, disablePadding: true, disableSort: true, label: '' },
      { id: 'name', numeric: false, disablePadding: true, label: 'Name' },

    ];

  }

}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

function GridDisplay(props) {

    const classes = useStyles();
    const { order, orderBy } = props;

    return (

        <GridContainer>

            {stableSort(rows, getSorting(order, orderBy)).map((row, index) => (
                <GridItem xs={9} sm={6} md={3} lg={3} key={row.name}>
                    <Link to={row.url}>
                    <Card plain product>
                    <CardHeader noShadow image>
                      {row.img}
                   </CardHeader>

                    <CardBody plain>
                        <Typography variant="body1" component="h2" className={classes.cardTitle}>
                            { row.name }
                            <br /><font size="2">
                              {Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(row.price)}
                            </font>
                        </Typography>

                        <Hidden xsDown implementation="js">
                          <p className={classes.description}>
                              {
                                row.fragranceSlug &&
                                  GetFragranceDescription(row.fragranceSlug)
                              }
                              {
                                row.fragranceSlug &&
                                   (
                                     <p/>
                                   )
                              }
                              {
                                (row.description.length >= 180) &&
                                  row.description.slice(0,180) + "... "
                              }
                              {
                                (row.description.length >= 180) &&
                                  (
                                    <Link underline='hover' to={row.url + "/" }>(MORE)</Link>
                                  )
                              }
                              {
                                (row.description.length < 180) &&
                                  row.description
                              }

                          </p>
                        </Hidden>
                    </CardBody>

                    </Card>
                    </Link>
                </GridItem>

            ))}

        </GridContainer>

    )
}

function getImage(imageName, altText) {
  const image = (
    <Image
      imgName={imageName}
      alt={altText}
      className="classes.imgFluid"
    />
  )
  return image
}

export default function ProductFamilyTemplate(props) {

  const classes = useStyles();

  loadTableData(props);

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(props.data.bannerImageMobile)
    } else {
      bannerImage = getSrc(props.data.bannerImage)
    }
  }

  var appendToH1;
  if (props.pageContext.locale.ogLanguageCode === "en_CA") {
      appendToH1 = "Scentsy Catalog Canada";
  } else {
      appendToH1 = "Scentsy Catalog USA";
  }

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

    return (
      <div>
        <HeaderTemplate
          locale={props.pageContext.locale}
          location={props.location}
        />
        <GatsbySeo
          title={props.data.contentfulProductFamily.name + props.pageContext.locale.appendToTitle}
          description={props.data.contentfulProductFamily.shortDescription}
          canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
          language={props.pageContext.locale.isoLanguageCode}
          languageAlternates={[
            {
              hrefLang: props.pageContext.locale.isoLanguageCode,
              href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
            },
            {
              hrefLang: props.pageContext.altLocale.isoLanguageCode,
              href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
          }
          ]}
          openGraph={{
            type: 'website',
            title: props.data.contentfulProductFamily.name + props.pageContext.locale.appendToTitle,
            description: props.data.contentfulProductFamily.shortDescription,
            url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
            site_name: 'SCENTSY Online Store | Home Fragrance Biz',
            locale: props.pageContext.locale.ogLanguageCode,
            images: [
              {
                url: siteMetadata.siteUrl + '/files/images/product/' + props.data.contentfulProductFamily.image.file.fileName,
                alt: 'Picture of ' + props.data.contentfulProductFamily.name,
                width: 600,
                height: 600
              }]
          }}
        />
        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: 'Home',
              item: 'https://homefragrancebiz.com',
            },
            {
              position: 2,
              name: 'SCENTSY catalog',
              item: siteMetadata.siteUrl + props.pageContext.locale.path + '/shop/',
            },
            {
              position: 3,
              name: props.data.contentfulProductFamily.category[0].name,
              item: props.pageContext.locale.path + '/shop/' + props.data.contentfulProductFamily.category[0].slug + '/',
            },
            {
              position: 4,
              name: props.data.contentfulProductFamily.name,
            },
          ]}
        />
        <Parallax
          image={bannerImage}
          filter="dark"
          small
          //style={{ paddingTop: "3%", height: "70vw", maxHeight: "500px" }}
        >

          <div className={classes.container}>
              <HeaderScentsy />
              <GridContainer>
                <GridItem
                  md={8}
                  sm={8}
                  key={props.data.contentfulProductFamily.slug}
                  className={classNames(
                    classes.mlAuto,
                    classes.mrAuto,
                    classes.textCenter
                  )}
                >
                  <div className={classes.brand}>
                    <Hidden only={['sm','md','lg','xl']} implementation="js">
                      <Typography variant="h5" component="h1" className={classes.title}>
                        {props.data.contentfulProductFamily.name}
                      </Typography>
                    </Hidden>
                    <Hidden only={['xs']} implementation="js">
                      <h1 className={classes.title}>
                        {props.data.contentfulProductFamily.name}
                      </h1>
                    </Hidden>
                    <Hidden only={['xs']} implementation="js">
                      <Typography variant="caption" component="p" className={classes.titleDescription}>
                        {props.data.contentfulProductFamily.shortDescription}
                      </Typography>
                    </Hidden>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>

          <div className={classNames(classes.main, classes.mainRaised)}>

            <Paper className={classes.paper}>

              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">

                <Typography variant="subtitle2" component="p">
                  <Link color="inherit" to={props.pageContext.locale.path + '/shop/'} >
                    SHOP
                  </Link>
                </Typography>

                <Typography variant="subtitle2" component="p">
                  <Link color="inherit" to={props.pageContext.locale.path + '/shop/' + props.data.contentfulProductFamily.category[0].slug + "/"} >
                    {props.data.contentfulProductFamily.category[0].name.toUpperCase()}
                  </Link>
                </Typography>

                <Typography color="textPrimary" variant="subtitle2" component="h1">
                  {props.data.contentfulProductFamily.name.toUpperCase()}
                </Typography>

              </Breadcrumbs>

              <Hidden smDown implementation="js">
                <font color="#777777" size="1"><i> {appendToH1}</i></font>
              </Hidden>

              <hr></hr>

              <Hidden xsDown implementation="js">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={3}

                >
                  <Grid item xs={8} sm={4}>
                    <GatsbyImage
                      image={props.data.contentfulProductFamily.image.gatsbyImageData}
                      alt={props.data.contentfulProductFamily.name} ></GatsbyImage>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h4" component="p">{props.data.contentfulProductFamily.name}</Typography>
                    {renderRichText(props.data.contentfulProductFamily.richDescription, options)}
                  </Grid>

                </Grid>

                <hr></hr>
              </Hidden>

              <GridDisplay order="asc" orderBy="name"/>

            </Paper>

        </div>
        <FooterTemplate
          locale={props.pageContext.locale}
        />
      </div>
    );
}